export const routes = [
    //比赛
    {
        path: '/match',
        name: 'match',
        meta:{title:'比赛·考试·作业'},
        component: () => import('@/views/match/index.vue'),

    },

    { name: 'matchDetail',meta:{title:''},path: '/match/matchDetails'
        , component: {template:"<router-view></router-view>"}
        , children: [
            { name: 'matchDetail1',meta:{title:'比赛详情'},path: '1',props: {type:1},component: () => import('@/views/match/MatchDetails.vue') },
            { name: 'matchDetail2',meta:{title:'考试详情'},path: '2',props: {type:2},component: () => import('@/views/match/MatchDetails.vue') },
            { name: 'matchDetail3',meta:{title:'作业详情'},path: '3',props: {type:3},component: () => import('@/views/match/MatchDetails.vue') },
        ],
    },


    // 新作业考试比赛
     { name: 'teachingTask',meta:{title:'作业'},props:{priexRoute:'course'},path: 'task',redirect: '/match/questOther/taskList'
            ,component: {template:"<router-view></router-view>"}
            ,children:[
                { name: 'teachingTaskList',meta:{title:'作业列表'},path: 'list',component: () => import('@/views/match/questOther/taskList.vue') },
                { name: 'teachingTaskInfo',meta:{title:'作业详情'},path: ':other_id(\\d+)/info',component: () => import('@/views/match/questOther/detail.vue') },
                { name: 'matchTaskCensus',meta:{title:'作业统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/match/questOther/census.vue') },
                { name: 'matchTaskQuestion',meta:{title:'作业题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/match/questOther/chooseList.vue') },
            ]
        },
     { name: 'teachingExam',meta:{title:'考试',props:{priexRoute:'exam'}},path: 'exam',redirect: '/match/questOther/examList'
                ,component: {template:"<router-view></router-view>"}
                ,children:[
                    { name: 'teachingExamList',meta:{title:'考试列表'},path: 'list',component: () => import('@/views/match/questOther/examList.vue') },
                    { name: 'teachingExamInfo',meta:{title:'考试详情'},path: ':other_id(\\d+)/info',component: () => import('@/views/match/questOther/detail.vue') },
                    { name: 'teachingExamCensus',meta:{title:'考试统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/match/questOther/census.vue') },
                    { name: 'matchExamQuestion',meta:{title:'考试题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/match/questOther/chooseList.vue') },
                ]
            },
     { name: 'teachingMatch',meta:{title:'比赛',props:{priexRoute:'match'}},path: 'match',redirect: '/match/questOther/matchList'
                ,component: {template:"<router-view></router-view>"}
                ,children:[
                    { name: 'teachingMatchList',meta:{title:'比赛列表'},path: 'list',component: () => import('@/views/match/questOther/matchList.vue') },
                    { name: 'teachingMatchInfo',meta:{title:'比赛详情'},path: ':other_id(\\d+)/info',component: () => import('@/views/match/questOther/detail.vue') },
                    { name: 'teachingMatchCensus',meta:{title:'比赛统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/match/questOther/census.vue') },
                    { name: 'matchMatchQuestion',meta:{title:'比赛题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/match/questOther/chooseList.vue') },
                ]
            },
            {
                name: '比赛',
                path: "/match/myMatchList",
                component: () => import('@/views/match/myMatchList.vue'),
            },{
                name: '考试',
                path: "/match/myCourseList",
                component: () => import('@/views/match/myCourseList.vue'),
            },{
                name: '作业',
                path: "/match/myExamList",
                component: () => import('@/views/match/myExamList.vue'),
            },
        //新-编辑考试添加考试
        { name: 'teachingExamCreate',meta:{title:'添加考试',login:true},path: '/match/exam/save'
            , component: {
                template:`<div style="margin-top: 20px;"><exam-save></exam-save></div>`,
                components:{
                    examSave:() => import('@/views/match/questOther/examSave.vue')
                }
            }
        },
        { name: 'teachingExamEdit',meta:{title:'编辑考试',login:true},path: '/match/exam/:other_id(\\d+)/save'
            , component: {
                props:['other_id'],
                template:`<div style="margin-top: 20px;"><exam-save :other_id="other_id"></exam-save></div>`,
                components:{
                    examSave:() => import('@/views/match/questOther/examSave.vue')
                }
            }
        },
        { name: 'teachingMatchCreate',meta:{title:'添加比赛',login:true},path: '/teaching/match/save'
        , component: {
            template:`<div style="margin-top: 20px;"><match-save></match-save></div>`,
            components:{
                matchSave:() => import('@/views/match/questOther/matchSave.vue')
            }
        }
        },
            { name: 'teachingMatchEdit',meta:{title:'编辑比赛',login:true},path: '/match/match/:other_id(\\d+)/save'
                , component: {
                    props:['other_id'],
                    template:`<div style="margin-top: 20px;"><match-save :other_id="other_id"></match-save></div>`,
                    components:{
                        matchSave:() => import('@/views/match/questOther/matchSave.vue')
                    }
                }
            },
            { name: 'teachingTaskCreate',meta:{title:'添加作业',login:true},props:{priexRoute:'course'},path: '/teaching/task/save'
                , component: {
                    template:`<div style="margin-top: 20px;"><task-save></task-save></div>`,
                    components:{
                        taskSave:() => import('@/views/match/questOther/taskSave.vue')
                    }
                }
            },
            { name: 'teachingTaskEdit',meta:{title:'编辑作业',login:true},props:{priexRoute:'course'},path: '/match/task/:other_id(\\d+)/save'
                , component: {
                    props:['other_id'],
                    template:`<div style="margin-top: 20px;"><task-save :other_id="other_id"></task-save></div>`,
                    components:{
                        taskSave:() => import('@/views/match/questOther/taskSave.vue')
                    }
                }
            },


    //学生 模拟考试
    {
        name: 'matchQuestAnswer',
        path: "/match/questAnswer",
        meta:{title:'比赛·考试·作业答题'},
        component: () => import('@/views/problemList/student/practiceExam.vue'),
    },
    {
        name: 'matchPreview',
        path: "/match/preview",
        meta:{title:'比赛·考试·作业:预览'},
        component: () => import('@/views/match/preview.vue'),
    },
    {
        name: 'matchOverDetails',
        path: "/match/overDetails",
        meta:{title:'答题详情',login:true},
        component: () => import('@/views/problemList/overDetails.vue'),
    },
    {
        name: 'matchOverTopicDetails',
        path: "/match/overTopicDetails",
        meta:{title:'答题题目详情',login:true},
        component: () => import('@/views/problemList/overTopicDetails.vue'),
    },
    {
        name: 'matchAnswerDetails',
        path: "/match/answerDetails",
        meta:{title:'做题详情',login:true},
        component: () => import('@/views/problemList/answerDetails.vue'),
    },
    {
        name: 'matchTiDanRecord',
        path: "/match/tiDanRecord",
        meta:{title:'答题记录',login:true},
        component: () => import('@/views/problemList/tiDanRecord.vue'),
    },
]